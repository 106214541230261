import { SVGProps } from "react"

const PortfolioSvg = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={20}
		height={20}
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 457.134 457.134'
		xmlSpace='preserve'
		{...props}
	>
		<path d='M367.37 279.117c0 6.681-5.416 12.098-12.098 12.098h-50.701c-6.681 0-12.098-5.416-12.098-12.098v-23.081H164.66v23.081c0 6.681-5.416 12.098-12.098 12.098h-50.701c-6.681 0-12.098-5.416-12.098-12.098v-23.081H25.166v180.48c0 11.387 9.231 20.618 20.618 20.618h365.565c11.387 0 20.618-9.231 20.618-20.618v-180.48H367.37v23.081zM435.757 85.818H317.222V20c0-11.045-8.954-20-20-20h-137.31c-11.046 0-20 8.955-20 20v65.818H21.377C9.99 85.818.759 95.049.759 106.436v114.989h89.006v-22.514c0-6.681 5.416-12.097 12.098-12.097h50.701c6.681 0 12.098 5.416 12.098 12.097v22.514h127.814v-22.514c0-6.681 5.416-12.097 12.098-12.097h50.701c6.681 0 12.098 5.416 12.098 12.097v22.514h89.005V106.436c-.002-11.387-9.233-20.618-20.621-20.618zM179.912 40h97.31v45.818h-97.31V40z' />
	</svg>
)

export default PortfolioSvg
